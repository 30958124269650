import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Components
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import CreditMemos from './CreditMemos'

// Icons
import { AddressCardIcon } from '../../components/AddressCardIcon'

// Utils & Service
import { getDealerBillingKpis } from '../../services/billing.service'
import { getDealer } from '../../services/dealer.service'
import { getDealers } from '../../services/dealers.service'
import { formatCurrency, formatMonthName } from '../../utils/formatters'
import { configureDealerAddress, formatPhoneNumber } from '../../utils/helpers'

const Billing = () => {
  const { dealerId } = useParams()
  const navigate = useNavigate()

  // State
  const [error, setError] = useState(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingKpis, setLoadingKpis] = useState(true)
  const [loadingDealer, setLoadingDealer] = useState(true)
  const [loadingDealers, setLoadingDealers] = useState(true)
  const [kpis, setKpis] = useState({})
  const [dealer, setDealer] = useState(null)
  const [dealers, setDealers] = useState([])

  const getPageDetails = async () => {
    const [kpisData, dealerData, dealersData] = await Promise.all([
      getDealerBillingKpis(dealerId, setLoadingKpis, setError),
      getDealer(dealerId, setLoadingDealer, setError),
      getDealers('/dealers/all', setLoadingDealers, setError),
    ])
    setKpis(kpisData)
    setDealer(dealerData)
    if (dealersData) setDealers(_.map(dealersData, (d) => ({ id: d.id, label: d.name })) || [])
    setFirstLoad(false)
  }

  useEffect(() => {
    if (dealerId) {
      getPageDetails()
    }
  }, [dealerId])

  const currentMonth = formatMonthName(kpis.currentMonth)
  const previousMonth = formatMonthName(kpis.previousMonth)

  return (
    <div className="bg-background flex h-full w-full flex-col overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <StateContainer error={error} loading={firstLoad}>
        <div className="flex w-full flex-col overflow-y-auto pb-12">
          <div className="mb-8 flex flex-wrap items-center justify-between gap-4">
            <div>
              <div>
                <Select
                  className="border-none bg-transparent pl-0 shadow-none"
                  options={dealers}
                  value={_.find(dealers, { id: dealer?.id })}
                  onChange={(o) => navigate(`/billing/${o.id}/detail`)}
                  disabled={loadingKpis || loadingDealer || loadingDealers}
                />
              </div>
              <Link
                className="text-xs font-bold uppercase text-blue-800 hover:underline"
                to={`/dealer/${dealerId}/detail`}
              >
                View Outstanding List
              </Link>
            </div>
            <div className="flex flex-row items-start justify-end gap-2 ">
              <AddressCardIcon className="w-10" />

              <div className="text-charcoal-900 flex flex-col items-end text-sm font-medium">
                {dealer?.address && <span>{dealer.address}</span>}
                {dealer?.address2 && <span>{dealer.address2}</span>}
                {configureDealerAddress(dealer) && <span>{configureDealerAddress(dealer)}</span>}
                {dealer?.phoneNumber && (
                  <a
                    href={`tel:${dealer.phoneNumber}`}
                    aria-label={`Call ${dealer.name} at ${formatPhoneNumber(dealer.phoneNumber)}`}
                    className="hover:text-blue-dark font-medium text-blue-800"
                  >
                    {formatPhoneNumber(dealer.phoneNumber)}
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="grid flex-none grid-cols-1 gap-2 sm:grid-cols-2  md:grid-cols-3">
            <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
              <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                {currentMonth} Running Total
              </span>
              <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                {formatCurrency(kpis.currentMonthTotal)}
              </span>
            </div>
            <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
              <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                {previousMonth} Total
              </span>
              <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                {formatCurrency(kpis.previousMonthTotal)}
              </span>
            </div>
            <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
              <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                YTD Through {previousMonth}
              </span>
              <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                {formatCurrency(kpis.ytdThruPrevMonth)}
              </span>
            </div>
          </div>
          <CreditMemos dealerId={dealerId} />
        </div>
      </StateContainer>
    </div>
  )
}

export default Billing
