// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the Dealers Billing Kpis.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealersBillingKpis = async (
  setLoading,
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/billing-kpis/`)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Dealers Billing Metrics.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealersBillingMetrics = async (
  url,
  setLoading,
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the individual Dealer Billing Kpis.
 * @param {string} id
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealerBillingKpis = async (
  id,
  setLoading,
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/${id}/billing-kpis/`)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Dealer Billing Cycles.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealerBillingCycles = async (
  dealerId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/dealers/${dealerId}/billing-cycles/?order_by=-start_date&limit=99999`,
    )
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Dealer Outstand Billing Cycles.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getOutstandingDealerBillingCycles = async (
  dealerId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/dealers/${dealerId}/billing-cycles/?order_by=start_date&limit=99999&period__is_closed=false&is_outstanding=true`,
    )
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Dealer Credit Memos.
 * @param {string} url
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealerCreditMemos = async (
  url,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.get(url)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete the Dealer Credit Memo.
 * @param {string} dealerId
 * @param {string} billingCycleId
 * @param {string} creditMemoId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const deleteCreditMemo = async (
  dealerId,
  billingCycleId,
  creditMemoId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.delete(
      `/dealers/${dealerId}/billing-cycles/${billingCycleId}/credit-memos/${creditMemoId}/`,
    )
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the billing cycle with the specified `payload`.
 * @param {string} dealerId
 * @param {string} billingCycleId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateBillingCycle = async (
  dealerId,
  billingCycleId,
  payload,
  setLoading,
  setError,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const { data } = await axios.patch(
      `/dealers/${dealerId}/billing-cycles/${billingCycleId}/`,
      payload,
    )
    setSuccess(true)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates the Credit Memo.
 * @param {string} dealerId
 * @param {string} billingCycleId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createCreditMemo = async (
  dealerId,
  billingCycleId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.post(
      `/dealers/${dealerId}/billing-cycles/${billingCycleId}/credit-memos/`,
      payload,
    )
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return false
  }
}

/**
 * Update the Credit Memo.
 * @param {string} dealerId
 * @param {string} billingCycleId
 * @param {string} creditMemoId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateCreditMemo = async (
  dealerId,
  billingCycleId,
  creditMemoId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.patch(
      `/dealers/${dealerId}/billing-cycles/${billingCycleId}/credit-memos/${creditMemoId}/`,
      payload,
    )
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return false
  }
}

export const createCreditMemoFile = async (
  dealerId,
  billingCycleId,
  creditMemoId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    await axios.post(
      `/dealers/${dealerId}/billing-cycles/${billingCycleId}/credit-memos/${creditMemoId}/files/`,
      payload,
    )
    setLoading(false)
    setSuccess(true)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
